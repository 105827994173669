// import config from 'config';

export const userService = {
    login,
    logout,
    getIncentiveData, 
    sendNotification,
};

function login(username, password) {
    const requestOptions = {        
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(process.env.VUE_APP_LOGIN_ENDPOINT, requestOptions)
        .then(handleResponse)
        .then(user => {
            let authRoleId=process.env.VUE_APP_Announcement_Host;
            // login successful if there's a jwt token in the response
            if (user.data && user.data.encryptedUserID.length>3 && user.data.authenticated==true) {
                if(!user.data.userRoles.includes(authRoleId))
                    throw 'Your are not authorized user';
                else
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user.data));
               
                //resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(user)) });
            }else
                throw 'Username or password is incorrect';
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

const authHeader = { 
    'Content-Type': 'application/json',
    'api_key': process.env.VUE_APP_BASE_AUTH 
}

function getIncentiveData(empId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader,
    };
    return fetch(`${process.env.VUE_APP_BASE_URL}/Connect/getincentiveannouncement?empId=${empId}`, requestOptions).then(handleResponse);
}

function sendNotification(empId,hostEmpId){
    const requestOptions = {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify({ empId, hostEmpId })
    };
    return fetch(`${process.env.VUE_APP_BASE_URL}/Connect/incentiveannouncementnotification`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}