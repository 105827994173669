import Vue from 'vue';
import Router from 'vue-router';

import LoginPage from '../views/Login.vue'
import HomePage from '../views/Home.vue'
import SlideShowPage from '../views/SlideShow.vue'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: HomePage },
    { path: '/slide', name:'slide', component: SlideShowPage },
    { path: '/login', component: LoginPage },

    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
      return next('/login');
  }

  next();
})