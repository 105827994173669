<template>
  <div id="app">
    <app-header />
    <div v-if="alert.message" :class="`alert ${alert.type} customAlert`">{{alert.message}}</div>    
    <router-view/>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import AppHeader from './components/AppHeader'
export default {
    name: 'app',
    components: { AppHeader },
    computed: {
        ...mapState({
            alert: state => state.alert
        }),
        
    },
    methods: {
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        
    },
    watch: {
        $route (to, from){
            // clear alert on location change
            this.clearAlert();
        }
    } 
};
</script>
<style scoped>

.customAlert {color: #333;
    /* background-color: #ffffff;
    border-color: #ffffff; */
    display: inline-block;
    width: 389px; margin: auto; display: flex;}
</style>