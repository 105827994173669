<template>
<div>
     <div class="dollarBox">
    <figure><img src="assets/images/dollarDay.png" class="img-responsive" alt="Dollar Day"></figure>
  </div>
  <div class="rankBox">
    <div class="owlBox" v-if="incData.length>0">
      <carousel v-if="disabledMultiStar===false" @changed="playAudio" :loop="true" :items="1" :margin="15" :nav="true" :rewind="false" :dots="false" :mouseDrag="false" :pullDrag="false" :touchDrag="false" :responsive="{480:{ items: 1},0:{ items: 1,autoWidth:false,nav:true,dots:false,}}">
        <multiple-star v-for="n in totalPage" :data="paginate(incDataBelow10K,pageSize,n)" :key="'ms_'+n" />       
        <star v-for="(incA,idxA) in incDataAbove10K" :data="incA" :key="'inc_'+idxA" />       
        <star v-for="(incB,idxB) in goalSheetAppraisal" :data="incB" :key="'gsa_'+idxB" />       
      </carousel>
      <carousel v-if="disabledMultiStar===true" @changed="playAudio" :loop="true" :items="1" :margin="15" :nav="true" :rewind="false" :dots="false" :mouseDrag="false" :pullDrag="false" :touchDrag="false" :responsive="{480:{ items: 1},0:{ items: 1,autoWidth:false,nav:true,dots:false,}}">              
        <star v-for="(incA,idxA) in incData" :data="incA" :key="'all_inc_'+idxA" />          
      </carousel>
        <!-- <template slot="perview"><button type="button" class="PlayBtn"><span aria-label="Preview"></span></button></template>    -->
        <!-- <template slot="next"><button type="button" class="PlayBtn"><span aria-label="Next"></span></button></template>    -->
      <!-- <audio id="myAudio_1" src="assets/audio/gong.mp3" @ended  ="playIpLAudio()">
      </audio> -->
      <audio id="myAudio" src="assets/audio/ipl_ringtone.mp3" :loop="true">
      </audio>
      <figure v-if="isMute"><img @click="toggleAudio" style="cursor: pointer; bottom: 50px; position: fixed;" src="assets/images/mute.png" class="img-responsive" alt="mute"></figure>
      <figure v-if="!isMute"><img @click="toggleAudio" style="cursor: pointer; bottom: 50px; position: fixed;" src="assets/images/unmute.png" class="img-responsive" alt="unmute"></figure>
    </div>    
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import carousel from 'vue-owl-carousel'
import star from "@/components/Star.vue";
import multipleStar from "@/components/MultipleStar.vue";

export default {
    components: { carousel, star, multipleStar },
    data () {
        return {
            isIPLPlay:false,
            isMute:false,
            slideCurrentIndex: 0,
            lastIndex:0,
            pageSize:5
        }
    },
    computed: {
        ...mapState({
            account: state => state.account,
            incData: state => state.account.incData,
            disabledMultiStar: state => state.account.disabledMultiStar
        }),
        incDataAbove10K(){
          return this.incData.filter(f=> (f.currency=='INR' && f.totalAmount>10000) || ['INR','Goal Sheet Appraisal'].indexOf(f.currency)==-1);
        },
        incDataBelow10K(){
          return this.incData.filter(f=>f.currency=='INR' && f.totalAmount<=10000);
        },
        goalSheetAppraisal(){
          return this.incData.filter(f=>f.currency=='Goal Sheet Appraisal');
        },
        totalPage(){
          return Math.ceil(this.incDataBelow10K.length/this.pageSize);
        }
    },
    methods:{
        ...mapActions('account', {
            sendNotification: 'sendNotification'
        }),
        toggleAudio(){
          const audio = document.getElementById("myAudio"); 
          if(audio){  
            this.isMute = !this.isMute;
            audio.muted = this.isMute;
          }
        },
        playAudio(event)
        {
          // if(this.isIPLPlay){
          //   let audio = document.getElementById("myAudio_2");
          //   if(audio){
          //       audio.pause();
          //       this.isIPLPlay=false;
          //   }
          // }
          // const currentIndex = event.item.index - event.relatedTarget._clones.length / 2;
          // const item = event.item;
          // console.log(item,'---',currentIndex);
          let audio = document.getElementById("myAudio");
          if (this.disabledMultiStar === true) {
            if (this.slideCurrentIndex == this.incData.length)
              this.$router.push('/');
            else {
              if (audio) {
                this.isMute = false;
                audio.muted = this.isMute;
                audio.currentTime = 0;
                audio.play();
              }
              let empId;
              const item=this.incData[this.slideCurrentIndex];
              if (item && item.notificationSent != 1)
                empId = item.employeeId.toString();
              if (empId) {
                this.sendNotification({ empId });
              }
              //console.log(item.employeeName,"-",item.totalAmount);
            }
          }
          else {
            if ((this.slideCurrentIndex - this.totalPage) == (this.incDataAbove10K.length + (this.goalSheetAppraisal.length || 0)))
              this.$router.push('/');
            // else if((this.slideCurrentIndex-this.totalPage)>=0) {
            //   let item=this.incDataAbove10K[this.slideCurrentIndex-this.totalPage]; 
            //   if(audio && item){
            //       audio.currentTime = 0;
            //       audio.play();   
            //       if(item.notificationSent != 1){ 
            //           let empId=item.employeeId;          
            //           this.sendNotification({empId});                    
            //       } 
            //       console.log(item);
            //   }
            // }
            else {
              if (audio) {
                this.isMute = false;
                audio.muted = this.isMute;
                audio.currentTime = 0;
                audio.play();
              }
              let empId;
              if ((this.slideCurrentIndex - this.totalPage) >= 0) {
                let item = this.incDataAbove10K[this.slideCurrentIndex - this.totalPage];
                if (item && item.notificationSent != 1)
                  empId = item.employeeId.toString();
              } else {
                let items = this.paginate(this.incDataBelow10K, this.totalPage, this.slideCurrentIndex + 1);
                let notifyItems = items.filter(f => f.notificationSent != 1);
                if (notifyItems && notifyItems.length > 0)
                  empId = notifyItems.map(m => m.employeeId).join();
              }
              if (empId) {
                this.sendNotification({ empId });
              }
            }
          }
          this.slideCurrentIndex++;
          //console.log(this.slideCurrentIndex, '-',(this.slideCurrentIndex-this.totalPage),'--',(this.incDataAbove10K.length+(this.goalSheetAppraisal.length || 0)));
        },
        playIpLAudio()
        {
          let audio = document.getElementById("myAudio_2");
          if(audio){
              this.isMute = false;
              audio.muted = this.isMute;
              audio.currentTime = 0;
              audio.play(); 
              this.isIPLPlay=true;    
          }
        },
        paginate(array, page_size, page_number){
            // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
            return array.slice((page_number - 1) * page_size, page_number * page_size);
        }
    },
    mounted()  {       
        if(!(this.incData && this.incData.length>0))
            this.$router.push('/');

        this.playAudio();
    },
    
}

</script>