import { userService } from '../_services';
import { router } from '../_helpers';

const state = { loading:true, status:{}, user:null, incData:[], totalPaidIncentive:'', incentiveFor:'' }

const actions = {
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });
    
        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        console.log('click here logout');
        userService.logout();
        commit('logout');
    },
    getIncentiveData({ dispatch, commit }) {
        commit('getDataRequest');
        let user=JSON.parse(localStorage.getItem('user'));
        userService.getIncentiveData(user.encryptedUserID)
            .then(
                result =>
                { 
                    if(result.status==1 && result.data ){
                        commit('getDataSuccess', result);                   
                        //router.push('slide');
                    }                        
                    else{
                        commit('getDataFailure', result)
                        dispatch('alert/error', result.message, { root: true });
                    }
                },
                error => commit('getDataFailure', error)
            );
    },
    sendNotification({  commit },{empId}){
        commit('notificationRequest');
        let user=JSON.parse(localStorage.getItem('user'));
        userService.sendNotification(empId, user.userID);
        commit('notificationSuccess');
            // .then(
            //     result =>commit('notificationSuccess', result),
            //     error => commit('notificationFailure', error)
            // );
    }    
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state = { loading:true, status: {}, user: null,incData:[] }
    },
    
    getDataRequest(state) {
        state.loading = true;
        state.incData = [];
        state.totalPaidIncentive = '';
        state.incentiveFor = '';
        state.disabledMultiStar = false;
    },
    getDataSuccess(state, result) {
        state.loading = false;
        state.incData = result.data ;
        state.totalPaidIncentive =`${result.currency} ${result.totalPaidIncentive}`;
        state.incentiveFor = result.incentiveFor ;
        state.disabledMultiStar = result.disabledMultiStar || false;
    },
    getDataFailure(state, error) {
        state.loading = false;
        state.incData = [];
        state.totalPaidIncentive = '';
        state.incentiveFor = '';
        state.disabledMultiStar = false;
    },

    notificationRequest(state){
        state.loading = true;
    },
    notificationSuccess(state){
        state.loading = false;
    }

};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};