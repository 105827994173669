<template>
      <header>
        <div class="logo">
          <figure><img src="assets/images/logo.png" alt="square yards logo"></figure>
        </div>
        <div class="signOut" title="Sign Out" v-if="loggedIn || status.loggedIn" v-on:click="AppLogout"><figure><img src="assets/images/logout.svg" alt="Sign Out"></figure></div>
      </header>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
          ...mapState('account', ['status']),
          loggedIn(){ return localStorage.getItem('user')!=null}
    },
    methods:{
        ...mapActions('account', ['logout']),
        AppLogout(){
          if(this.loggedIn || this.status.loggedIn)
          {
              this.logout();
              //location.reload(true);
              location.replace("/");
          }
        }
    }
}
</script>