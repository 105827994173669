<template>
  <div v-if="incData && incData.length>0">
   <div class="dollarBox">
		<figure><img src="assets/images/dollarDay.png" class="img-responsive" alt="Dollar Day"/></figure>
	</div>
	<!-- <div class="rewordText">
		Total incentive Paid in this Month<br><span>{{totalPaidIncentive}}</span>
	</div> -->
	<div class="starBox">
		<figure><img src="assets/images/star.png" alt="Star Image"></figure>
			<div class="playBtnPosition">
			<div class="playBtn">
			<img src="assets/images/play.svg" v-on:click="play" alt="Play Button"/>	
			</div>
			<span>{{incentiveFor}}</span>
			</div>
	</div>
  </div>
  <div v-else-if="loading==true">
   <div class="dollarBox">
		<figure><img src="assets/images/dollarDay.png" class="img-responsive" alt="Dollar Day"/></figure>
	</div>	
	<div class="starBox">
		<figure class="starRotate"><img src="assets/images/star.png" alt="Star Image"></figure>			
	</div>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	computed:{
		getMonthYr(){
			const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
			];
			if(this.incData && this.incData.length>0)
				return this.incData[0].month;
			else{	
				const today = new Date();
				return `${monthNames[today.getMonth()]} ${today.getFullYear()}`;
			}
			
		},
		...mapState({
            loading: state => state.account.loading,
            incData: state => state.account.incData,
            totalPaidIncentive: state => state.account.totalPaidIncentive,
            incentiveFor: state => state.account.incentiveFor
        }),
		// getTotalAmount(){
		// 	const totalAmount = this.incData.map(m=>m.totalAmount).reduce((a, b) => a + (b || 0), 0);
		// 	const currency = this.incData["incData"]||'INR';
		// 	return `${currency} ${totalAmount.toLocaleString()}`;
		// }
	},
	methods:{
		...mapActions('account', {
            getSlideData: 'getIncentiveData'
        }),
		play:function(){
			this.$router.push({ name: 'slide' })
		}
	},
	created(){
		this.getSlideData();
	}
}
</script>
