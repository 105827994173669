<template>
    <div class="item">
          <div class="card">
            <figure><img src="assets/images/rankCard.png" class="img-responsive" alt="Rank Card"></figure>
            <div class="userImg">
              <figure v-if="data.profilePicture.length>0"><img :src="data.profilePicture" class="img-responsive img-circle" alt="User Image"></figure>
              <figure v-else ><img src="assets/images/avatarImg.png" class="img-responsive" alt="User Image"></figure>
            </div>
            <div class="contentBox">
              <div class="rank1"></div>
              <div class="name"> <span class="first">{{data.employeeName}}</span> <span class="second">{{data.t2EmpName}}</span> </div>
              <div v-if="data.currency==='Goal Sheet Appraisal' ||  Number(data.totalAmount) <= 0" class="price">{{data.currency}}</div>
              <div v-else class="price">{{data.currency +' '+ Number(data.totalAmount).toLocaleString()}}/-</div>
            </div>
          </div>
    </div>  
</template>
<script>
export default {
  name: 'Star',
  props: { data: {type: Object, required: true} }
}
</script>