<template>
    <div class="item">
        <div class="cardMain">
            <div class="card" v-for="(inc,idx) in data" :key="idx">
                <figure><img src="assets/images/rankCard.png" class="img-responsive" alt="Rank Card"></figure>
                <div class="userImg">
                    <figure v-if="inc.profilePicture.length>0">
                        <img :src="inc.profilePicture" class="img-responsive img-circle" alt="User Image">
                    </figure>
                    <figure v-else >
                        <img src="assets/images/avatarImg.png" class="img-responsive" alt="User Image">
                    </figure>
                </div>
                <div class="contentBox">
                    <div class="rank1"></div>
                    <div class="name"> <span class="first">{{inc.employeeName}}</span> <span class="second">{{inc.t2EmpName}}</span> </div>
                    <div v-if="Number(data.totalAmount) <= 0" class="price">{{inc.currency}}</div>
                    <div v-else class="price">{{inc.currency +' '+ Number(inc.totalAmount).toLocaleString()}}/-</div>
                </div>
          </div>
        </div>
    </div>    
</template>
<script>
export default {
  name: 'MultipleStar',
  props: { data: { type: Array, required: true } }
}
</script>