<template>
 <div class="loginBox">
	 <div class="login">
	 <h1>Login with your E-Code</h1>
	 <span>Your information is safe with us.</span>
	 <form @submit.prevent="handleSubmit">
	 	<div class="form-group">
            <input type="text" v-model="username" name="username" placeholder="Enter E-Code" class="form-control" :class="{ 'is-invalid': submitted && !username }" />            
			<div v-show="submitted && !username" class="invalid-feedback">E-Code is required</div>
		</div>
		 <div class="form-group">
			 <input type="password" v-model="password" name="password" placeholder="Enter Password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />             
			 <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
		</div>
         <button class="submitBtn" :disabled="status.loggingIn" >Login Now</button>
	 </form>
 </div>
 </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            username: '',
            password: '',
            submitted: false
        }
    },
    computed: {
        ...mapState('account', ['status'])
    },
    created () {
        // reset login status
        this.logout();
    },
    methods: {
        ...mapActions('account', ['login', 'logout']),
        handleSubmit (e) {
            this.submitted = true;
            const { username, password } = this;
            if (username && password) {
                this.login({ username, password })
            }
        }
    }
};
</script>